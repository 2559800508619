import React from "react";
import { ThemeProvider, theme, CSSReset, Box } from "@chakra-ui/core";
import Nav from "components/Nav";
import Head from "next/head";
import useStripeLoader from "hooks/useStripeLoader";
import { Elements } from "@stripe/react-stripe-js";
import { QueryClient, QueryClientProvider } from "react-query";

const customTheme: any = {
  ...theme,
  fonts: {
    ...theme.fonts,
    heading: `Roboto, ${theme.fonts.heading}`
    // body: `Open Sans, ${theme.fonts.body}`,
    // mono: `Roboto Mono, ${theme.fonts.mono}`
  }
};

const queryClient = new QueryClient();

const Container: React.FC = ({ children }) => {
  const stripeData = useStripeLoader();
  return (
    <ThemeProvider theme={customTheme}>
      <CSSReset />
      <Head>
        <link
          // href="https://fonts.googleapis.com/css?family=Open+Sans|Roboto|Roboto+Mono&display=swap"
          href="https://fonts.googleapis.com/css?family=Roboto&display=swap"
          rel="stylesheet"
        />
      </Head>
      <Elements stripe={stripeData}>
        <QueryClientProvider client={queryClient}>
          <Nav />
          <Box p={8}>{children}</Box>
        </QueryClientProvider>
      </Elements>
    </ThemeProvider>
  );
};

export default Container;
