import React from "react";
import { Text, Flex, Box } from "@chakra-ui/core";
import Link from "components/Link";

const links = [
  { href: "/events/fall-2021-banquet", label: "Banquet Tickets" },
  // { href: "/donate", label: "Donate" },
];

const Nav = () => (
  <Flex
    p={4}
    pl={8}
    pr={8}
    justifyContent="space-between"
    bg="purple.600"
    color="white"
  >
    <Link href="http://lodge804.com/#!/alumni" color="white">
      <Text fontSize="xl">Alpha Xi Alumni Association</Text>
    </Link>

    <Flex>
      {links.map(({ href, label }) => (
        <Box pl={6} key={href}>
          <Link href={href} color="white">
            {label}
          </Link>
        </Box>
      ))}
    </Flex>
  </Flex>
);

export default Nav;
