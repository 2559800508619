import { AppConfig, Stage } from "lib/types";

export const STAGE: Stage =
  (process.env.NEXT_PUBLIC_VERCEL_ENV as Stage) || Stage.Development;
export const BUILD = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || STAGE;

export const __PROD__ = STAGE === Stage.Production;
export const __DEV__ = STAGE === Stage.Development;
export const __DEBUG__ = STAGE !== Stage.Production;
export const __SERVER__ = !!process.env.VERCEL;
export const __CLIENT__ = !process.env.VERCEL;

const printVersion = () => {
  console.info(`🔍\nSTAGE: ${STAGE}\nBUILD: ${BUILD}`);
};

if (__DEBUG__ && __CLIENT__) {
  printVersion();
}

const result = require(`./stages/${STAGE}`);
const config: AppConfig = result.default;

export default config;
