import { AppConfig } from "lib/types";

const config: AppConfig = {
  stripePublicKey: "pk_test_8PfVxZtdMqcvSmOAO2OPisIE00vsKVimGI",
  donationOptions: {
    MONTHLY: "plan_Fqy27dgyxiSwPs",
    QUARTERLY: "plan_Fqy51XfXOzbaJi",
    YEARLY: "plan_Fqy5oEQARsfPre",
    ONE_TIME: "sku_Gng05hFmdjOxSL",
  },
};

export default config;
