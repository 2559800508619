export enum Stage {
  Development = "development",
  Preview = "preview",
  Production = "production",
}

export interface AppConfig {
  stripePublicKey: `pk_${"test" | "live"}_${string}`;
  donationOptions: {
    MONTHLY: `plan_${string}`;
    QUARTERLY: `plan_${string}`;
    YEARLY: `plan_${string}`;
    ONE_TIME: `sku_${string}`;
  };
}
