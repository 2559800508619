import React from "react";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import config from "config";

const useStripeLoader = () => {
  const [stripeData, setStripeData] = React.useState<null | Stripe>(null);

  React.useEffect(() => {
    loadStripe(config.stripePublicKey).then(stripe => {
      setStripeData(stripe);
    });
  }, []);

  return stripeData;
};

export default useStripeLoader;
