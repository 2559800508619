import { AppProps } from "next/app";
import Container from "components/Container";

function App({ Component, pageProps, router }: AppProps) {
  if (router.route === "/") {
    return <Component {...pageProps} />;
  }
  return (
    <Container>
      <Component {...pageProps} />
    </Container>
  );
}

export default App;
