import React from "react";
import NextLink, { LinkProps } from "next/link";
import { Link as ChakraLink, Icon } from "@chakra-ui/core";

const Link: React.FC<LinkProps & React.ComponentProps<typeof ChakraLink>> = ({
  children,
  href,
  isDisabled,
  isExternal,
  color,
  ...nextLinkProps
}) => (
  <NextLink href={href} passHref {...nextLinkProps}>
    <ChakraLink
      isDisabled={isDisabled}
      isExternal={isExternal}
      color={color || "blue.500"}
    >
      {children}
      {isExternal && (
        <>
          {" "}
          <Icon name="external-link" mx="2px" />
        </>
      )}
    </ChakraLink>
  </NextLink>
);

export default Link;
