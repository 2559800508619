import { AppConfig } from "lib/types";

const config: AppConfig = {
  stripePublicKey: "pk_live_aAM6NOL7xI1R2Toel1HqbikY00atSam69B",
  donationOptions: {
    MONTHLY: "plan_KPVsSASXSveDCO",
    QUARTERLY: "plan_KPVspG1MXWk78T",
    YEARLY: "plan_KPVsRWrg29sUHa",
    ONE_TIME: "sku_KPVs7sK4SwLrmG",
  },
};

export default config;
